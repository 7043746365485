.container{
    
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    overflow: hidden;
}


.main{
    display: grid;
    grid-template-columns: auto repeat(8, 10rem) auto ;
    grid-template-rows: auto repeat(5, 10rem) auto;
    grid-template-areas: 
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". . title title title title fishing1 about ."
      ". skills zion zion zion venice portfolio snowboard monica"
      ". . building blog . snowboard2 snowboard2 contact fishing2 ";
      backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}
.box{
    transition: .5s all;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
    z-index: 1;
    padding: 0;
    border: 0;
    background: none;
    outline: none;
    font-size: 1rem;
}
.box:hover{
    transform: scale(1.1);
    z-index: 100;
}
.title{
    grid-area: title;
}
.fishing1{
    grid-area: fishing1;
}
.about{
    grid-area: about;
}

.portfolio{
    grid-area: portfolio;
}
.zion{
    grid-area: zion;
}
.snowboard1{
    grid-area:snowboard;
}
.skills{
    grid-area: skills;
}
.venice{
    grid-area: venice;
}
.building{
    grid-area: building;
}
.monica{
    grid-area: monica;
}
.blog{
    grid-area: blog;
}
.snowboard2{
    grid-area: snowboard2;
}
.fishing2{
    grid-area: fishing2;
}
.contact{
    grid-area: contact;
}
.box20{
    background-color: yellow;
    grid-area: footer;
}
.box21{
    background-color: orange;
    grid-area: last
}

@media only screen and (max-width: 1300px){
    .main{
        grid-template-areas: 
        ". . . . . . . . ."
        ". . . . . . . . ."
        ". . title title title title fishing1 about ."
        ". skills zion zion zion venice portfolio snowboard ."
        ". . building blog fishing2 snowboard2 snowboard2 contact . "
        ". . . monica . . . . .";
    }
}
@media only screen and (max-width: 1150px){
    .main{
        grid-template-columns: auto repeat(6, 10rem) auto ;
        grid-template-areas: 
        ". . . . . . . . ."
        ". . . . . . . . ."
        ". about title title title title fishing1 . ."
        ". skills zion zion zion venice portfolio . ."
        ". . building blog fishing2 snowboard2 snowboard2 . . "
        ". . . monica snowboard contact . . .";
    }
    
}
@media only screen and (max-width: 967px){
    .main{
        grid-template-columns:  repeat(5, 10rem)  ;
        grid-template-rows:  repeat(6, 10rem) ;
        grid-template-areas: 
        
        " . . . . . .  "
        " about title title title title .  "
        " skills zion zion zion venice .  "
        " portfolio building blog snowboard2 snowboard2  . "
        "  fishing1 monica fishing2 snowboard contact .  "
        " . . . . . .  ";
    }
}
@media only screen and (max-width: 800px){
  
    .main{
        
        grid-template-columns:  repeat(4, 7rem)  ;
        grid-template-rows:  repeat(6, 7rem) ;
        grid-template-areas: 
        
        "  title title title title   "
        "  title title title title   "
        "  zion zion zion about   "
        "  building skills snowboard2 snowboard2   "
        "  portfolio fishing2 snowboard contact   "
        "  venice blog monica fishing1   ";
    }
   
}
@media screen and (max-height: 850px) and (max-width: 550px){
    .main{
        grid-template-columns:  repeat(4, 5rem)  ;
        grid-template-rows:  repeat(6, 5rem) ;

    }
    
}