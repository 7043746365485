.container{
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(
        to right bottom,
        rgba(0,0,0, .6),
        rgba(0,0,0, .6)),
        url("../media/aboutback.jpg");
    background-size: cover;
    background-repeat: none;
    background-position: center;

    color: ghostwhite;
}
.main{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.box{
    transition: .5s all;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    z-index: 1;
    padding: 0;
    border: 0;
    background: none;
    outline: none;
    margin: 1.5rem;
    font-size: 1rem;
}
.box:hover{
    transform: scale(1.1);
    z-index: 100;
}
.nav{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}
.left{
    width: 40%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding-right: 7rem;
}
.title{
    background: var(--greyBlue);
    padding: 1.5rem 3rem 1.5rem 3rem;
    font-size: 3rem;
    font-weight: 300;
    letter-spacing: 8px;
}
.header{
    margin-top: 3rem;
    font-size: 1.5rem;
    font-weight: 300;
    text-align: right;
    letter-spacing: 3px;
}


.right{
    width: 40%;
    font-size: 1.2rem;
    font-weight: 300;
    letter-spacing: 2px;
}
.subheader{
    margin-top: 4rem;
}
.p1{
    width: 70%;
    margin-bottom: 2rem;
}
.p2{
  width: 70%;
}
@media only screen and (max-width: 1921px){
    .right{
        width: 50%;
        font-size: 1rem;
    }
    .left{
       
    }
}
@media only screen and (max-width: 1018px){
    .box{
        margin: 0;
    }
    .right{
        width: 100%;
    }
    .p1{
        width: 100%;
    }
    .p2{
        width: 100%;
    }
    .title{
        padding: 2rem;
    }
    .left{
        width: fit-content;
        padding-right: 2rem;
    }
    .nav{
        margin-top: 3rem;
    }
}
@media only screen and (max-width: 850px){
    .right{
        font-size: 1rem;
    }
}
@media only screen and (max-width: 700px){
    .left{
        width: 90%;
        justify-content: center;
        align-items: center;
    }
    .header{
        font-size: 1.2rem;
    }
    .title{
        font-size: 1.7rem;
    }
    .right{
        width: 80%;
    }
    .main{
        flex-direction: column;
    }
}
@media screen and (max-height: 850px) and (max-width: 550px){
    .subheader{
        margin-top: 0rem;
    }
    .title{
        font-size: 1.6rem;
        padding: .5rem;
    }
    .header{
        margin-top: 0rem;
    }
    .nav{
        display: none;
    }
}
@media screen and (max-height: 775px) and (max-width: 450px){
    .p1, .p2{
        font-size: .8rem;
    }
    .main{
        justify-content: flex-end;
    }
    .right{
        margin-bottom: 2rem;
    }
}