.main{
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(
        to right bottom,
        rgba(0,0,0, .6),
        rgba(0,0,0, .6)),
        url("../media/introback.jpg");
    background-size: cover;
    background-repeat: none;
    background-position: center;
    overflow: hidden;

}
