.container{
    display: flex;
    width: 10rem;
    height: 10rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fefefa;
   
  
}
.icon{
    font-size: 2rem;
    margin-bottom: .5rem;
}

@media only screen and (max-width: 800px){
    .container{
        width: 7rem;
        height: 7rem;
    }
}
@media screen and (max-height: 850px) and (max-width: 550px){
    .container{
        width: 5rem;
        height: 5rem;
    }
    .icon{
        font-size: 1rem;
    }
}