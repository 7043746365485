.container{
    height: 11rem;
    width: 17rem;
    border-radius: 3px;
    overflow: hidden;
    margin: 1rem;

    transition: 1s all;
    backface-visibility: hidden;
}
.container:hover{
    transform: scale(1.1);
    cursor: pointer;
}

.text{
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: 1s all;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 200;
    padding: 1rem;
    
}
.textcontainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.container:hover .text{
    visibility: visible;
    opacity: 1;
    z-index: 100;
    
}

.container:hover img{
    filter: brightness(20%);
}
.container:hover .title{
    filter: brightness(20%);
}
.main{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.image{
    height: 65%;
    width: 100%;
}
.img{
    height: 100%;
    width: 100%;
}
.container:hover > .overlay {
    position: absolute;
    top: 0;
    width:100%;
    height:100%;
    left: 0;
    background-color:#000;
    opacity:0.5;
    z-index: 100;
    display: block;
}
.title{
    height: 20%;
    color: white;
    font-weight: 200;
    letter-spacing: 3px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    background: rgba(51, 51, 141, 0.158);
   
}
.github{
    height: 15%;
    background: rgba(255, 252, 248, 0.308);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem 0 1rem;
}
.git{
    color: white;
    text-decoration: none !important; 
    transition: all .5s;
}
.githubicon:hover{
    color: #6e5494;
}
.git:hover{
    transform: scale(1.3);
}

@media only screen and (max-width: 1306px){
    .container{
        margin: 0rem;
    }
}
@media only screen and (max-width: 1306px){
    .container{
        height: 12rem;
        width: 12rem;
        margin: .5rem;
    }
    .title{
        font-size:1rem;
    }
    .github{
        height: 25%;
    }
}
@media screen and (max-height: 850px) and (max-width: 550px){
    .container{
        height: 10rem;
        width: 12rem;
        margin: .5rem;
    }
    .title{
        font-size: .8rem;
    }
    .github{
        font-size: .8rem;
    }
}
@media screen and (max-height: 812px) and (max-width: 415px){
    .container{
        height: 12rem;
        width: 15rem;
        margin: .5rem;
        margin: 1rem auto;
    }
    .lang{
        display: none;
    }

}