.container{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
ul{
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
}
ul li{
    position: relative;
    list-style: none;
    width: 60px;
    height: 60px;
    margin: 0 2rem;
    transform: rotate(-30deg) skew(25deg);
    background: #ccc;
}
ul li span{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    transition: .5s;
    display: flex !important;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 2rem !important;
}
ul li:hover span{
    box-shadow: -1px 1px 1px rgba(0,0,0,.2);
}
ul li:hover span:nth-child(5){
    transform: translate(40px, -40px);
    opacity: 1;
}
ul li:hover span:nth-child(4){
    transform: translate(30px, -30px);
    opacity: .8;
}
ul li:hover span:nth-child(3){
    transform: translate(20px, -20px);
    opacity: .6;
}
ul li:hover span:nth-child(2){
    transform: translate(10px, -10px);
    opacity: .4;
}
ul li:hover span:nth-child(1){
    transform: translate(0, 0);
    opacity: .3;
}
ul li:nth-child(1) span{
    background: #6e5494;
}
ul li:nth-child(2) span{
    background: #0077b5;
}
ul li:nth-child(3) span{
    background: #d34836;
}
ul li:nth-child(4) span{
    background: #006400;
    color: black;
    
}
@media only screen and (max-width: 700px){
    ul li{
        width: 40px;
        height: 40px;
        margin: 1rem;
    }
}