.container {
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(
      to right bottom,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url("../media/blog.jpg");
  background-size: cover;
  background-repeat: none;
  background-position: center;

  color: ghostwhite;
}
.title {
  background: var(--secondBlue);
  padding: 1.5rem 3rem 1.5rem 3rem;
  font-size: 3rem;
  font-weight: 300;
  letter-spacing: 8px;
  text-align: center;
  margin-bottom: 2rem;
}
.main {
  padding-top: 5rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}
.nav {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
.box {
  transition: 0.5s all;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  z-index: 1;
  padding: 0;
  border: 0;
  background: none;
  outline: none;
  margin: 1.5rem;
  font-size: 1rem;
}
.box:hover {
  transform: scale(1.1);
  z-index: 100;
}
.posts {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.post {
  position: relative;
  width: 20rem;
  height: 100%;
  margin: 2rem;
  overflow: auto;
  transition: 0.2s all;
}
.post:hover {
  transform: scale(1.05);
}
.post:hover .image {
  filter: brightness(20%);
}
.post:hover .postTitle {
  filter: brightness(20%);
}
.post:hover .date {
  filter: brightness(20%);
}
.post:hover .preview {
  filter: brightness(20%);
}
.post:hover .image {
  filter: brightness(20%);
}

.darker {
  display: flex;
  height: 70%;
  width: 100%;
  position: absolute;
  justify-content: center;
  align-items: flex-start;
  visibility: hidden;
}
.post:hover .darker {
  visibility: visible;
  z-index: 100;
}
.readmore {
  background: rgb(128, 128, 128);
  padding: 0.5rem 1rem 0.5rem 1rem;
  transition: 0.2s all;
  border-radius: 2px;
  transition: 0.2s all;
  text-decoration: none;
  color: white;

  margin-top: 10rem;
}
.readmore:hover {
  cursor: pointer;
  background: none;
}
.image {
  width: 100%;
  height: 15rem;
  transition: 0.2s all;
}
.postTitle {
  font-size: 2rem;
  background: rgba(31, 31, 87, 0.158);
  transition: 0.2s all;
}
.date {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: rgba(31, 31, 87, 0.158);
  transition: 0.2s all;
}
.preview {
  background: rgba(31, 31, 87, 0.158);
  transition: 0.2s all;
}
.pagination {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagi {
  margin: 0 20rem 0 20rem;
}
.pagi:hover {
  cursor: pointer;
}
.pagiright {
  position: relative;
  padding: 25px 30px;
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 4px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 15% 50%, 0% 0%);
}
.pagiright:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
}
.pagiright:after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(225, 255, 255, 0.4),
    transparent
  );
  transition: 0.5s;
}
.pagiright:hover:after {
  left: 100%;
}
.pagiright span {
  position: absolute;
  display: block;
  transition: 0.5s ease;
}
.pagiright span:nth-child(1) {
  top: 0;
  left: 0;
  width: 0;
  height: 1px;
  background: #fff;
}
.pagiright:hover span:nth-child(1) {
  width: 100%;
  transform: translateX(100%);
}

.pagileft {
  position: relative;
  padding: 25px 30px;
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 4px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  clip-path: polygon(100% 0%, 85% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
}
.pagileft:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
}
.pagileft:after {
  content: "";
  position: absolute;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(225, 255, 255, 0.4),
    transparent
  );
  transition: 0.5s;
}
.pagileft:hover:after {
  right: 100%;
}
.pagileft span {
  position: absolute;
  display: block;
  transition: 0.5s ease;
}
.pagileft span:nth-child(1) {
  top: 0;
  right: 0;
  width: 0;
  height: 1px;
  background: #fff;
}
.pagileft:hover span:nth-child(1) {
  width: 100%;
  transform: translateX(-100%);
}
.labels {
  text-decoration: none;
  color: rgb(143, 143, 143);
  z-index: 1000;
}
.labels:hover{
    cursor: pointer;
}
@media only screen and (max-width: 2050px) {
  .pagi {
    margin: 0 15rem 0 15rem;
  }
  .darker {
    align-items: flex-start;
  }
  .main {
    padding-top: 0rem;
  }
}
@media only screen and (max-width: 1920px) {
  .pagi {
    margin: 0 10rem 0 10rem;
  }
}
@media only screen and (max-width: 1750px) {
  .pagi {
    margin: 0 3rem 0 3rem;
  }
}
@media only screen and (max-width: 1500px) {
  .post {
    position: relative;
    width: 15rem;
    height: 30rem;
    margin: 2rem;
    overflow: auto;
    transition: 0.2s all;
  }
  .image {
    height: 10rem;
  }

  .postTitle {
    font-size: 1.4rem;
  }
  .preview {
    font-size: 0.9rem;
  }
  .date {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
  .readmore {
    margin-top: 5rem;
  }
}
@media only screen and (max-width: 1200px) {
  .box {
    display: none;
  }
}
@media only screen and (max-width: 942px) {
  .pagination {
    align-items: flex-end;
    justify-content: center;
    height: 33rem;
  }
  .post {
    height: 27rem;
  }
}
@media only screen and (max-width: 500px) {
  .container {
    height: 50rem;
  }
  .pagination {
    height: 35rem;
  }
  .pagi {
    font-size: 0.6rem;
  }
  .title {
    font-size: 1.5rem;
  }
}
