:root{
    --mainBlue: rgb(6,136,149);
    --secondBlue: #74B3CE;
    --greyBlue: #508991;
    --darkBlue: #1c486b;
    --greenBlue: #004346;
    --lightGreen: #09BC8A;
    overflow-x: hidden;
  
}
.section > .fp-tableCell{
    height: auto !important;
    padding: none !important;

}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    
}
.background{
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(
      to right bottom,
      rgba(0,0,0, .6),
      rgba(0,0,0, .6)),
      url("./media/introback.jpg");
  background-size: cover;
  background-repeat: none;
  background-position: center;
  overflow: hidden;

}

.loader {

  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
  background: var(--secondBlue);
}

.loader--hide {
  opacity: 0;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}