.container{
    width: 100%;
    height: 100%;
    max-width: 5rem;
    font-size: 5rem;
}
.image{
    width: 5rem;
    height: 5rem;
    
}