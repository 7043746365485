.container{
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(
        to right bottom,
        rgba(0,0,0, .6),
        rgba(0,0,0, .6)),
        url("../media/portback3.jpg");
    background-size: cover;
    background-repeat: none;
    background-position: center;
    color: ghostwhite;
    max-width: 100%;

} 

.main{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.box{
    transition: .5s all;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    z-index: 1;
    padding: 0;
    border: 0;
    background: none;
    outline: none;
    margin: 2rem 0 2rem 0;
    font-size: 1rem;
}
.box:hover{
    transform: scale(1.1);
    z-index: 100;
    
}

.right{
    margin-right: 2rem;
}
.left{
    margin-left: 2rem;
}
.mid{
    width: 60%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
   margin-top: 2rem;
}
.title{
    background: var(--lightGreen);
    padding: 1.5rem 3rem 1.5rem 3rem;
    font-size: 3rem;
    font-weight: 300;
    letter-spacing: 8px;
    text-align: center;
    margin-bottom: 2rem;
}
.projects{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    transition: 1s all;
    width: 90%;
    
}
@media only screen and (max-width: 1700px){
    .main{
        justify-content: space-between
    }
    .mid{
        width: 100%;
        padding-bottom: 5rem;
    }
    .container{
        height: auto;
    }
    
}
@media only screen and (max-width: 1306px){
    .right{
        margin-right: 0rem;
    }
    .left{
        margin-left: 0rem;
    }
    .main{
        justify-content: space-between;
    }
    .mid{
        padding-top: 2rem;
    }
    .title{
        margin-top: 1rem;
    }
    .projects{
        width: 100%;
    }
}
@media only screen and (max-width: 1018px){
    .box{
        margin: 0;
    }
    .nav{
    
    }
}

@media screen and (max-height: 850px) and (max-width: 700px){
    .title{
        font-size: 1.4rem;
        padding: 1rem;
        margin-bottom: 2rem;
    }
    .nav{
        display: none;
    }
    .mid{
        width: 100%;
    }
    .container{
        height: auto;
    }
    
}
@media screen and (max-height: 850px) and (max-width: 415px){
    .container{
        height: auto;
    }
    .project{
        
        flex: 0 0 100%;
        width: 100%;
    }
}